<template>
  <div class="activity">
    <div v-for="(item, index) in list" class="list">
      <div class="title">
        <img src="@/assets/img/activity/xin.png" alt="" srcset="" />
        <span>{{ item.title }}</span>
        <img src="@/assets/img/activity/xin.png" alt="" srcset="" />
      </div>
      <div class="content-bg">
        <div v-for="(i, i_index) in item.content" :key="i_index">
          <div class="name">{{ i_index + 1 }}、{{ i.title }}</div>
          <p v-for="(j, j_index) in i.children" :key="j_index">
            （{{ j_index + 1 }}）{{ j }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "新人福利",
          content: [
            {
              title: "注册福利：",
              children: [
                "注册5金币",
                "注册即可参与免费新人ROLL房",
              ],
            },
            {
              title: "加群福利：",
              children: [
                "加群即可领取3金币加群CDK（还有加群免费密码ROLL房）",
                "加群的用户管理员会不定时的发放红包码：红包码数量有限",
                "群人数福利：达到500、1000、1500、2000人，发放群人数福利CDK（数量有限）",
              ],
            },
            {
              title: "首冲奖励：",
              children: [
                "充值任意额度可以参加新人首冲ROLL房",
                "首次充值20赠送10%金币",
              ],
            },
            {
              title: "累充奖励：",
              children: [
                "每日累计充值任务福利",
                "每日充值完成任务送cdk",
              ],
            },
          ],
        },
        {
          title: "每日任务",
          content: [
            {
              title: "每日累充：",
              children: [
                "每日充值任意金额 奖励2~20金币(限量)",
                "绑定Steam即可参与免费新人ROLL房",
                "实名认证获得5金币",
              ],
            },
            {
              title: "签到奖励：",
              children: [
                "累计签到满7天赠送10金币",
                "累计充值满14天赠送20金币",
                "累计充值满28天赠送40金币",
              ],
            },
            {
              title: "开箱消费奖励：",
              children: [
                "累计开箱消费流水满100，赠送2-10金币",
                "累计开箱消费流水满600，赠送5-20金币",
                "累计开箱消费流水满1000，赠送10-50金币",
                "累计开箱消费流水满1500，赠送20-100金币",
                "累计开箱消费流水满2000，赠送30-200金币",
                "累计开箱消费流水满15000，赠送100-500金币",
              ],
            },
           {
              title: "对战消费奖励：",
              children: [
                "累计对战消费流水满100，赠送2-10金币",
                "累计对战消费流水满600，赠送5-50金币",
                "累计对战消费流水满1000，赠送10-50金币",
                "累计对战消费流水满1500，赠送20-100金币",
                "累计对战消费流水满2000，赠送30-200金币",
                "累计对战消费流水满15000，赠送100-500金币",
              ],
            },
          ],
        },
        {
          title: "其他福利",
          content: [
            {
              title: "对战排行榜：",
              children: [
                "对战排行榜前三名 奖励100，50，20金币",
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .content-bg {
    padding: 60px 20px 20px 20px !important;
  }
  .list {
    margin: 30px 10px !important;
  }
  .title {
    font-size: 32px !important;
  }
}
.activity {
  color: #fff;
  padding: 50px 0;
  .list {
    max-width: 900px;
    margin: 0px auto;
    padding: 20px 0;
    .content-bg {
      background-image: url("../../assets/img/activity/activity-item-bg.75e1a5af.png");
      background-size: 100% 100%;
      min-height: 775px;
      overflow-y: auto;
      padding: 100px 60px 60px 60px;
      & > div {
        margin-bottom: 10px;
      }
      .name {
        font-weight: bold;
        margin: 20px 0 20px 0;
      }
      p {
        margin-bottom: 7px;
      }
    }
    .title {
      background-image: -webkit-linear-gradient(
        bottom,
        #adb5c6,
        #c4cad6,
        #fdfefe
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 44px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
